<template>
  <div class="mt-10">
    <service-hero></service-hero>
    <mobile-services-catalog></mobile-services-catalog>
    <all-services-component></all-services-component>
  </div>
</template>

<script>
import MobileServicesCatalog from '@/components/MobileServicesCatalog.vue';
import ServiceHero from '@/components/ServiceHero.vue';
import AllServicesComponent from '@/components/AllServicesComponent.vue';

export default {
  name: 'MobileServices',
  components: {
    AllServicesComponent,
    ServiceHero,
    MobileServicesCatalog,
  },
  metaInfo: {
    title: 'UcarMobile: Auto Repair by Best Mobile Car Mechanics',
    meta: [
      {
        name: 'description',
        content: 'Best mobile car repair service near you. Easy and convenient. Reliable and trustworthy mobile auto repair. Get an instant estimate. All makes and models.',
      },
      {
        name: 'keywords',
        content: 'auto repair, mobile mechanic, find local mechanics, mobile car repair, car repair, find mechanic, mechanic, auto shop, auto repair shop, car mechanic',
      },
    ],
  },
};
</script>
